import React from 'react';
import Header from './components/Header.js';
import Footer from './components/Footer.js';
const Livestream = () => {
	return (
           <div>
            <Header/>
            <div class="form-container">
                <h2>Live Streaming</h2>
             </div>
           <Footer/>
           </div>
        );
    };
    export default Livestream;