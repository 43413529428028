
import React from 'react'
import Header from './components/Header'
import Footer from './components/Footer'
const About = () => {
  return (
    <div>
        <Header/>
        <div class="form-container">
            <h2>About Us</h2>
        </div>
        <Footer/>
        
        </div>

  )
}

export default About
