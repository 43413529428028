import React from 'react';
import { FaHome,FaTachometerAlt } from "react-icons/fa";
import {FcAbout} from "react-icons/fc";
import{CiStreamOn} from "react-icons/ci";
import { Link } from "react-router-dom";
import './Header.css';
import './Footer.css';
const Footer = () => {
	return (
		<div className="Main-title">
		
			<ul>
                <li>
                <Link to="/"><FaHome size="1.5em" color="white"/></Link>
                </li>
                <li> <Link to="/about"><FcAbout size="1.5em" color="white"/></Link></li>
                <li><Link to="/stream"><CiStreamOn size="1.5em" color="white"/></Link></li>
            </ul>
          

		</div>
	);
};
export default Footer;
